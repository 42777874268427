import React from 'react'
import Helmet from 'react-helmet'
import {Link} from 'gatsby'
import Gallery from '../components/Gallery'
import Layout from '../components/layout'
import { HomePageImages } from '../components/Gallery/constants/homePageImages'

const HomeIndex = () => {
  const siteTitle = 'Ffynnon Felen - Luxury Accomodation'
  const siteDescription = '10 guest accomodation in the heart of West Wales'

  return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
        <meta name="description" content={siteDescription} />
      </Helmet>

      <div id="main">
        <section id="one">
          <header className="major">
            <h2>
              Welcome to Ffynnon Felen, 
              <br />
              your luxury home from home.
            </h2>
          </header>
          <p>
          Welcome to our charming period stone farmhouse set in idyllic countryside in West Wales.</p>

<p>Our five hundred year old property has been renovated to a high standard, providing a luxury stay in beautiful surroundings. 
  There are two very large living areas which are perfect for family gatherings with karaoke, games, tv and 100mb internet. </p> 

<p>On good weather days there are  facilities, outdoor seating and lots of land and woods to get lost in. Of course we also have a 
  large hot tub the overlooks the beautiful wooded valley which can be used on good days and bad! For wet weather days we have a table
   football table, air hockey and a darts board - sure to bring out the competitive side of the family! </p>
<p>All our bedrooms are a good size and come with comfortable beds, hairdryers and towels are provided. There are two en-suites, one shared bathroom and one WC.</p>
<p>If you want to get out and about we are a short drive to many local attractions. Tenby, Pendine and Laugharne Castle (Dylan Thomas' home) are just some of the places you can visit.</p>
<br></br>
        
          <ul className="actions">
            <li>
              <Link to="/about" className="button">
                Our Facilities
              </Link>
            </li>
          </ul>
        </section>

        <section id="two">
          <h2>Gallery</h2>

          <Gallery images={HomePageImages} />

          <ul className="actions">
            <li>
              <Link to="/gallery" className="button">
                See more
              </Link>
            </li>
          </ul>
        </section>

        <section id="three">
          <h2>Get In Touch</h2>
          <p>
            If you have any questions or would like to make a booking please feel free to get in touch. 
            Prices vary throughout the season and discounts are available for longer bookings.
          </p>
          <div className="row">
            <div className="8u 12u$(small)">
              <form method="post" action="#">
                <div className="row uniform 50%">
                  <div className="6u 12u$(xsmall)">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Name"
                    />
                  </div>
                  <div className="6u 12u$(xsmall)">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email"
                    />
                  </div>
                  <div className="12u">
                    <textarea
                      name="message"
                      id="message"
                      placeholder="Message"
                      rows="4"
                    ></textarea>
                  </div>
                </div>
                <ul className="actions" style={{ marginTop: 30 }}>
                  <li>
                    <input type="submit" value="Send Message" />
                  </li>
                </ul>
              </form>
            </div>
            <div className="4u 12u$(small)">
              <ul className="labeled-icons">
                <li>
                  <h3 className="icon fa-home">
                    <span className="label">Address</span>
                  </h3>
                  Ffynnon Felen
                  <br />
                  Cwmbach, Whitland
                  <br />
                  Carmarthenshire
                  <br/>
                  SA34 0DN
                </li>
                <li>
                  <h3 className="icon fa-mobile">
                    <span className="label">Phone</span>
                  </h3>
                  +44 7496 899 268
                </li>
                <li>
                  <h3 className="icon fa-envelope-o">
                    <span className="label">Email</span>
                  </h3>
                  <a href="mailto: hello@ffynnonfelen.co.uk">hello@ffynnonfelen.co.uk</a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default HomeIndex
