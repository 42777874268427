import full01 from '../../../assets/images/fulls/01_f.jpg'
import full02 from '../../../assets/images/fulls/02_f.jpg'
import full03 from '../../../assets/images/fulls/03_f.jpg'
import full04 from '../../../assets/images/fulls/04_f.jpg'
import full05 from '../../../assets/images/fulls/05_f.jpg'
import full06 from '../../../assets/images/fulls/06_f.jpg'
import thumb02 from '../../../assets/images/thumbs/02_t.jpg'
import thumb01 from '../../../assets/images/thumbs/01_thumb.jpg'
import thumb03 from '../../../assets/images/thumbs/03_t.jpg'
import thumb04 from '../../../assets/images/thumbs/04_t.jpg'
import thumb05 from '../../../assets/images/thumbs/05_t.jpg'
import thumb06 from '../../../assets/images/thumbs/06_t.jpg'


export const HomePageImages = [
    {
      id: '1',
      source: full01,
      thumbnail: thumb01,
      caption: '',
      description: 'Front of the property',
    },
    {
      id: '2',
      source: full02,
      thumbnail: thumb02,
      caption: '',
      description: 'Rear of the property',
    },
    {
      id: '3',
      source: full03,
      thumbnail: thumb03,
      caption: '',
      description: 'View over the valley',
    },
    {
      id: '4',
      source: full04,
      thumbnail: thumb04,
      caption: '',
      description: 'King size bedroom with en-suite',
    },
    {
      id: '5',
      source: full05,
      thumbnail: thumb05,
      caption: '',
      description: 'One of the large living rooms',
    },
    {
      id: '6',
      source: full06,
      thumbnail: thumb06,
      caption: '',
      description: 'View from the hot tub',
    }
]